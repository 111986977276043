
/**
 * Projects
 */
.filter-button {
    border: 1px solid $gold;
    border-radius: 0px;
    text-align: center;
    color: $gold;
    margin-bottom:30px;

    &:hover,&.active{
        border: 1px solid $gold;
        color: $white;
        background-color: $gold;
    }
}
.gallery_product {
    margin-bottom: 30px;
}

p.subtitle{
    margin:0 auto;
    text-align:center;
    max-width:80%;
    padding-bottom:30px;
    color:$light-grey;
    font-style: italic;
}

.grid{
    .grid-item{
        padding:5px;
    }
}

.grid-item-content{
    height:450px;
    width:100%;
    position: relative;
    overflow: hidden;
    cursor:pointer;

    figure{
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background-size:cover;
        background-position:center;

        -webkit-transition : all 0.35s linear;
        transition: all 0.35s linear;
    }

    figcaption {
        position: absolute;
        left: 0;
        width: 100%;

        padding: 2em;
        color: $white;
        text-transform: uppercase;
        font-size: 1.25em;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        top: auto;
        bottom: 0;
        padding: 1em;
        height: auto;
        background: rgba(255,255,255,0.85);
        color: #3c4a50;
        -webkit-transition: -webkit-transform 0.35s;
        transition: transform 0.35s;
        -webkit-transform: translate3d(0,100%,0);
        transform: translate3d(0,100%,0);


        &::before,&::after{
            pointer-events:none;
        }

        h2 {
            font-size:1.15em;
            float:left;
            display: inline-block;
            text-align: left;
            margin:0;
            &:after{
                margin-left:0;
            }
        }
        h2,p{
            margin:0;
        }

        h2,a{
            -webkit-transition: -webkit-transform 0.35s;
            transition: transform 0.35s;
            -webkit-transform: translate3d(0,200%,0);
            transform: translate3d(0,200%,0);
        }
    }
    &:hover{
        figure{
            -webkit-filter: brightness(.7) blur(3px);
            filter: brightness(.7) blur(3px);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        figcaption,h2,a{
            -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
        }
        h2{
            -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
        }
    }
}
