.nb-error {
    margin: 0 auto;
    text-align: center;
    max-width: 680px;
    padding: 60px 30px;

    .error-code {
        color: $gold;
        font-size: 96px;
        line-height: 100px;
    }

    .error-desc {
        font-size: 12px;
        color: #647788;
    }

    .search-form{
        max-width:400px;
        margin:0 auto;
    }

    .search-field{
        padding: 0 12px;
        font-size: 14px;
        line-height: 46px;
        border-right:none;
        height:46px;
    }
    .input-group-addon{
        height:46px;
        padding:0;
        background-color:transparent;
        border:none;
    }
}
