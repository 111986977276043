.topbar{
    background-color:$blue;

    .container{
        position:relative;
    }

    @media (max-width: 580px){
        display:none;
    }

    .nav-force-left{
        position:absolute;
        left:0;
    }

    .navbar-nav>li{
        float:left;
    }

    .navbar-nav.navbar-right{
        float:right !important;
        margin:0;

        @media (max-width: 660px){
            &>li>a{
                padding:5px;
            }
        }
    }

    .navbar-nav>li>a{
        padding-top: 5px;
        padding-bottom:5px;
        font-size:0.8em;

        color:$white;

        &:hover{
            background-color:$gold;
        }
    }
}

.navbar-default .navbar-toggle{
    border-radius:0;
    margin-top: 23px;
    margin-bottom: 23px;

    &:hover{
        background-color:white;
        border-color:$gold;

        .icon-bar{
            background-color:$gold;
        }
    }
}

.navbar-default .navbar-collapse{
    background-color:white;
    overflow:hidden;

    @media (max-width: 1100px) {
        margin: 0 -15px;
    }
}
.navbar-default .navbar-collapse.in{

    .navbar-nav>li>a:hover{
        &:after{
            display:none;
        }
    }

    .button-item{
        padding:15px;
        padding-top:0;
    }

    .dropdown-menu{
        position:initial;
        float:none;
        box-shadow:none;
        border:none;
        padding-left:30px;
    }
}

.navbar.navbar-default .navbar-nav>li.button-item>a{
    color:$gold;
    margin-right:15px;
    padding: 10px 15px;
    margin-top: 6px;

    &:hover,&:focus{
        color:$white;
        background-color:$gold;
    }
}

.navbar.navbar-default{
    height:80px;
    margin-bottom:0;
    padding-bottom:0;
    background-color:$white;
    border:none;

    .navbar-nav{
        margin-top: 15px;
    }
    .navbar-brand{
        height:80px;

        img{
            height: 36px;
            margin-top: 7px;
            margin-left: 10px;
        }
    }
    .navbar-nav>.open>a,
    .navbar-nav>li>a{
        font-family:$secondary-font;
        background-color:transparent;

        &:hover{
            position:relative;
            color:$blue;
            font-weight:bold;
            background-color:transparent;

            //border-bottom:2px solid $blue;
            &:after{
                content: ' ';
                display:block;
                height:2px;
                width:30px;
                margin-top:5px;
                margin-left:-15px;
                left:50%;
                background-color:$gold;
                position:absolute;
            }
        }
    }
}
.navbar-nav>li{
    .dropdown-menu{
        border-radius:0;

        &>li>a{
            font-family:$secondary-font;
            background-color:transparent;
            color:$dark-grey;

            &:hover{
                background-color:transparent;
                color:$blue;
                font-weight:bold;
            }
        }
    }
}

.button-item{
    a{
        @extend .btn;
        @extend .btn-secondary;
    }
}

//break nav faster
.navbar-header>.back-button {
  display: none;
}

@media (max-width: 1100px) {
    .wpcf7-form-control-wrap{
        z-index:0;
    }
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }

    .navbar-header>.back-button {
      display:block;
      position: initial;
      float: left;
      margin: 15px;
    }

    .back-button {
      display: none;
    }
}
