.slider{
    width:100%;

    .slick-slide{
        padding:0 2px;
    }

    .slick-slide:focus, figure:focus{
        outline:none;
    }

    figure{
        width:100%;
        height:400px;
        max-height:60vh;
        background-size:cover;
        background-repeat: no-repeat;
    }

    .slick-next, .slick-prev{
        height:30px;
    }
    .slick-next:before,.slick-prev:before{
        font-family:'FontAwesome';
        font-size:30px;
    }

    .slick-next{
        right:15px;

        &:before{
            content: '\f105';
        }
    }
    .slick-prev{
        left:15px;
        z-index:1;

        &:before{
            content: '\f104';
        }
    }
}
