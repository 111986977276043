/*
 * ABOUT US
 */
.timeline{
    overflow:hidden;
}
.timeline-item {
    padding: 3em 2em 2em;
    position: relative;
    color: rgba(0, 0, 0, 0.7);

    img{
        max-width:80%;
        padding-bottom:15px;
    }
}

@media (min-width:992px){
    .timeline-item.left {
        border-right: 2px solid rgba(0, 0, 0, 0.3);
        text-align:right;
        margin-left:2px;

        &::before{
            left:initial;
            right:1em;
            text-align:right;
        }
        &::after{
            right: -6px;
            left:initial;
        }
    }
}
@media (max-width:820px){
    .timeline{
        margin: 0 20px;
    }
}
@media (max-width:770px){
    .timeline{
        margin: 0 40px;
    }
}
@media (max-width: 991px){
    .timeline-item.left{
        border-left: 2px solid rgba(0, 0, 0, 0.3);
        text-align:left;

        &::before{
            left:1em;
            right:initial;
            text-align:left;
        }
        &::after{
            left: -6px;
            right:initial;
        }
    }
}
.timeline-item.right {
    border-left: 2px solid rgba(0, 0, 0, 0.3);
    text-align:left;

    &::before{
        left:1em;
        right:initial;
        text-align:left;
    }
    &::after{
        left: -6px;
        right:initial;
    }
}
.timeline-item::before {
    content: attr(data-is);
    position: absolute;
    left: 2em;
    font-weight: bold;
    top: .5em;
    display: block;
    font-family: $secondary-font;
    font-weight: 700;
}
.timeline-item::after {
    width: 10px;
    height: 10px;
    display: block;
    top: 1em;
    position: absolute;
    left: -7px;
    border-radius: 10px;
    content: '';
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
}
.timeline .row:last-child .timeline-item {
    -o-border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 60%, transparent) 1 100%;
    border-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 60%, transparent) 1 100%;
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 60%, transparent) 1 100%;
}
