.spinner-wrapper{
  position:absolute;
  z-index: 999;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color:$white;
}
.spinner {
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -25px;

  width: 50px;
  height: 50px;
  background-color: $gold;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}
