body{
    font-family: $primary-font;
    color:$black;
    line-height: 1.8;
    letter-spacing:1px;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}
h1{
    margin-top:60px;
    margin-bottom:30px;
    text-align:center;
    &::after{
        width:40px;
        margin: 0 auto;
    }
}
h1,h2,h3,h4,h5{
    font-family: $secondary-font;
    text-transform:uppercase;
    color:$blue;

    &:after{
        content: ' ';
        display:block;
        height:2px;
        margin-top:5px;
        background-color:$gold;
    }

    &.center,
    &.text-center,
    &[style*="text-align: center"],
    &[style*="text-align:center"]{
      text-align:center;
      &::after{
        margin: 5px auto 0;
      }
    }
}

.back-button{
  position:absolute;
  left:0;
  z-index:1;
}

.table{
    font-size: 0.71em;
}

.main .wpb_content_element{
    margin-bottom:0;
}

.main{
  position: relative;
}


.map{
    width:100%;
    height:350px;
    overflow:hidden;
}

.sub-menu{
    @extend .dropdown-menu;
}

h2{
    margin:80px 0 60px;
    &:after{
        width:60px;
    }
}
h3:after{
    width:20px;
}
a{
    color:$gold;

    &:hover,&:active{
        color:$blue;
    }
}
.btn{
    border:none;

    &:hover,&:focus{
        border:none;
    }
}
.btn-primary {
    color: $white;
    background-color: $blue;

    &:hover,&:focus{
        background-color: $gold;
        color:$white;
    }
}
.btn{
    border-radius:0;
    padding: 12px 18px;
}
.btn-secondary {
    color:$gold;
    background-color:transparent;
    border: 1px solid $gold;

    &:hover,&:focus{
        border: 1px solid $gold;
        background-color: $gold;
        color:$white;
    }
}

@media (max-width: 991px){
    .vc_column_container[class*=" vc_col-sm"],
    .page-header{
        padding-left:15px;
        padding-right:15px;
    }
    .vc_row-fluid{
        padding-bottom:15px;
    }
    .sticky-social{
        display:none;
    }
    .container .grid  .grid-item{
        width:100%;
        padding-left:10px;
        padding-right:10px;
    }

    .sidebar{
        display:none;
    }
}

.post img{
    max-width: 100%;
}


.text-center{
    text-align:center;
}

#phoneModal{
    .modal-header,.modal-footer{
        border:none;
    }
    .modal-body{
        font-weight:bold;
        font-size:2em;
        text-align: center;
    }
}

.modal-content{
    border-radius: 0;
}
