
.meta{
    color: #A0A8AB;
    font-size: 13px;
    margin-bottom: 30px;
    border-top: 1px solid #F2F2F3;
    border-bottom: 1px solid #F2F2F3;
    padding: 5px;

    a{
        color: #A0A8AB;

        &:hover{
            color:$gold;
        }
    }

    .fa{
        margin-right: 5px;
    }
    span::after {
        content: '/';
        margin: 0 15px;
    }
    span:last-of-type::after {
        content: '';
        margin: 0;
    }
}

.socialmedia{
    margin-top:60px;
    text-align:center;

    &>span{
        color:$dark-grey;
        display:block;
        font-size:0.9em;
        padding-bottom:10px;
        padding-top:20px;
    }
    a {
        color:#fff;
        background: $blue;
        border-radius:4px;
        text-align:center;
        text-decoration:none;
        font-family:fontawesome;
        position: relative;
        display: inline-block;
        width:40px;
        height:40px;
        padding-top:8px;
        margin:0 2px;
        -o-transition:all .5s;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
        -webkit-font-smoothing: antialiased;
    }

    a:hover {
        background: $gold;
    }

    /* pop-up text */

    a span {
        color:$white;
        position:absolute;
        font-family:sans-serif;
        bottom:0;
        left:-25px;
        right:-25px;
        padding:5px 7px;
        z-index:-1;
        font-size:14px;
        border-radius:2px;
        background:$black;
        visibility:hidden;
        opacity:0;
        -o-transition:all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -webkit-transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -moz-transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    /* pop-up text arrow */

    a span:before {
        content:'';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $black;
        position:absolute;
        bottom:-5px;
        left:40px;
    }

    /* text pops up when icon is in hover state */

    a:hover span {
        bottom:50px;
        visibility:visible;
        opacity:1;
    }


    a:nth-of-type(1):before {
        content:'\f09a';
    }
    a:nth-of-type(2):before {
        content:'\f099';
    }
    a:nth-of-type(3):before {
        content:'\f08c';
    }
    a:nth-of-type(4):before {
        content:'\f003';
    }
}


/**
    Blog
 */
.blog-list article{
    margin: 0;
    padding: 60px;
    margin-bottom: -1px;
    clear:both;

    .btn-wrapper{
        margin-top:20px;


        @media (max-width: 991px){
            .btn{
                width:100%;
            }
        }
    }

    h2{
        text-transform: none;
        margin-bottom:20px;
        margin-top:0px;

        a{
            color:$blue;

            &:hover{
                color:$gold;
            }
        }

        &:after{
            display:none;
        }
    }

    .summary.half,.thumb{
        width: 50%;
        float: left;
        margin-bottom: 30px;

        @media (max-width: 991px){
            width: 100%;
            float: none;
        }
    }

    .thumb{
        transition: opacity 0.4s;
        padding-right: 30px;

        @media (max-width: 991px){
            padding:0;
        }

        &:hover {
            opacity: 0.7;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .summary{
        color:$dark-grey;
        font-family: $primary-font;

        p:last-of-type {
            margin-bottom: 0;
        }
    }
    .read-more{
        a{
            margin-bottom:60px;
        }
        border-bottom:1px solid #F2F2F3;
    }
    padding-bottom:0;
}
.blog-list{
    .col-md-3{
        padding-top:60px;
    }
    aside{
        padding:0;
    }
    .widget{
        margin: 0 0 -1px;
        padding: 30px;
        border-top: 1px solid #F2F2F3;
        border-bottom: 1px solid #F2F2F3;

        h3{
            text-transform: uppercase;
            margin: 0 0 10px;
            font-weight:normal;
            font-size:1.2em;

            &:after{
                display:none;
            }
        }

        ul{
            list-style: none;
            padding: 0;

            li{
                padding-top: 2px;
                padding-bottom: 2px;
                font-size:0.9em;

                a{
                    color:$blue;

                    &:hover,&:active{
                        color:$gold;
                    }
                }
            }
        }

        &.widget_archive{
            li::before{
                content: '\f133';
                margin-right: 8px;
            }
        }

        &.widget_categories li::before {
            content: '\f114';
            margin-right: 8px;
        }

        li::before{
            font-family: Fontawesome;
            font-size: 15px;
        }
    }
}

.page .content,body .post{
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding-left: 1.2em;

            &:before {
                content: "•";
                margin-right:7px;
                color: $gold; /* or whatever color you prefer */
                font-size: 1.5em;
                line-height: 1em;
                position: relative;
                top: 4px;
            }

            li{
                padding-left:4em;

                li{
                    padding-left:6em;
                }
            }
        }
    }
}
