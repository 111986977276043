.sticky-social{
    right: 0;
    position: fixed;
    top: 150px;
    z-index:1;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        background: $blue;
        color: #fff;
        display: block;
        height: 35px;
        line-height: 35px;
        position: relative;
        text-align: center;
        width: 35px;
        margin-bottom:2px;

        span {
            line-height: 35px;
            right: -120px;
            position: absolute;
            text-align:center;
            width:120px;
            transition: all 0.2s ease-out;
            font-family:'Open Sans', sans-serif;
            font-size:0.9em;
        }

        &:hover span {
            right: 100%;
        }

        &[class*="facebook"] span,
        &[class*="facebook"]:hover{ background: #3b5998; }
        &[class*="linkedin"] span,
        &[class*="linkedin"]:hover{ background: #0077b5; }
        &[class*="pinterest"] span,
        &[class*="pinterest"]:hover{ background: #bd081c; }
        &[class*="youtube"] span,
        &[class*="youtube"]:hover{ background: #cd201f; }
        &[class*="twitter"] span,
        &[class*="twitter"]:hover{ background: #1da1f2; }
        &[class*="instagram"] span,
        &[class*="instagram"]:hover{ background: #f56040; }
    }
}
