
body .sub-cat{
    border:1px solid lighten($light-grey,20);
    margin-bottom:30px;
    display:block;
    overflow:hidden;

    @media (max-width: 767px){
        margin-left: 30px;
        margin-right: 30px;
    }

    color:$dark-grey;

    &:hover{
        color:$dark-grey;
        text-decoration:none;
    }

    .col-md-5{
        min-height:300px;
        padding-right:0;
        .img{
            width:100%;
            height:300px;
            background-size:cover;
            background-repeat:no-repeat;
        }
    }
    h2{
        text-align:left;
        font-size:1.4em;
        margin:0;
        margin-bottom:20px;
        margin-top:15px;

        &:after{
            margin:0;
            margin-top:10px;
        }
    }



    @media (max-width:991px){
        h2, .content{
            padding-right:20px;
            padding-left: 20px;
        }
    }

    .content{
        font-size:0.95em;
        padding-right:20px;
        padding-left: 20px;

        ul{
            list-style-type: none;
            padding:0;

            li{
                margin-bottom:10px;
                list-style:none;
                &:before {
                    font-family: 'FontAwesome';
                    content: '\f067';
                    margin:0 5px 0 -15px;
                    font-size:1em;
                    color:$black;
                    position:initial;
                }

                li{
                    margin-left:25px;
                    padding-left:0;
                    margin-top:5px;
                }
            }
        }
    }
}
