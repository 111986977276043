.footer{
    margin-top:80px;
    background-color:$blue;
    color:$dark-white;
    padding-top:20px;

    a{
        color:$dark-white;
        &:hover{
            color:$gold;
        }
    }

    .social-icons{
        padding-top:20px;
        a{
            padding-right:10px;
        }
    }

    footer{
        text-align: center;
        padding:20px;
    }
}
