.brands{
    text-align: center;
    font-size: 0;
    letter-spacing: -4px;

    [class*='vc_col-'] {
        display: inline-block;
        margin: 0 auto;
        vertical-align: top;
        letter-spacing: 0;
        font-size: 14px;
        margin-bottom:30px;
        float: none;
        height:60px;
        white-space: nowrap;

        &::before{
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }

    img{
        max-width:100%;
        max-height:100%;
        width: auto;
        height: auto;
        vertical-align: middle;

        -webkit-filter: grayscale(1);
        filter: grayscale(1);

        &:hover{
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
        }
    }
}
