// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$blue: #003359;
$gold: #a98024;
$black: #545454;
$white: #FFF;

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Montserrat', sans-serif;

$dark-grey: darken($black,0.8);
$dark-white: darken($white,0.2);
$light-grey: lighten($black,35);
$brand-primary: $blue;
