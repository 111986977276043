.form-control{
    border-radius:0;
    box-shadow:none;

    &:focus,&:active{
        border-color:$gold;
        box-shadow:none;
    }
}

.widget input[type=text],
.widget input[type=search],
.widget input[type=email]{
    @extend .form-control;
}
.widget input[type=submit]{
    @extend .btn;
    @extend .btn-secondary;
}

.widget .btn, .widget input[type=submit]{
    padding: 6px 11px;
}


.widget input[type=search]{
    border-right:none;
}
.widget .input-group-addon{
    padding:0;
    border-radius:0;
    border:none;
    background-color:transparent;

    button{
        margin:0;
    }
}

.contact-form{

    .form{
        width: 100%;
        padding:15px;
        background:#f8f8f8;
        border:1px solid rgba(0, 0, 0, 0.075);
        margin-bottom:10px;
        color:#727272 !important;
        font-size:13px;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
    }

    .form:hover{
        border:1px solid $gold;
    }
    .full-width{
        width:100%;
    }

    .table{
        font-size: 0.71em;
    }

    .form:focus{
        color: white;
        outline: none;
        border:1px solid $gold;
    }

    .textarea{
        height: 185px;
        max-height: 185px;
        max-width: 100%;
    }
    input {
        position: relative;
        z-index: 9999;
    }

    .row{
        margin-left:-5px;
        margin-right:-5px;

        .col-xs-6,.col-xs-12{
            padding:0 5px;
        }
    }
}
