.full-size-bg{
    margin-top:-20px;
    padding-top:80px;
    height:70vh;
    background-size: cover;
    background-position: center center;
    text-align: center;
    margin-left:-15px;
    margin-right:-15px;

    .tagline{
        width:400px;
        max-width:90%;
    }

    .down-arrow{
        position:absolute;
        bottom:20px;
        left:50%;
        margin-left:-15px;
        width:30px;
        height:30px;
        text-align:center;
        color:white;
        font-size:1.8em;
    }
}

.icon-block{
    transition: background-color 0.5s ease;
    margin: 0 -15px;
    display:block;

    @media (max-width: 767px){
        margin:0;
    }

    &:hover{
        background-color:$gold;
        color:$white;
        text-decoration:none;

        p{
            color:$dark-white;
        }

        img{
            /*
             * TODO: IE support
             */
            -webkit-filter: invert(100%);
            filter: invert(100%);
        }
        h3{
            color:$white;

            &:after{
                background-color:$white;
            }

        }
    }
    img{
        margin-top:15px;
        max-width:48px;
        max-height:48px;
        width: auto;
        height: auto;
    }
    h3{
        font-size:0.9em;
    }
    p{
        padding:10px;
        color:$dark-grey;
        font-style: italic;
    }
}

.blogpost{
    &.portrait .content{
        height:750px;
    }
    &.landscape .content{
        height:360px;
    }
    .img-wrapper{
        display:block;
        background-size:0 0;
    }
    h3 a{
        color:$blue;

        &:hover{
            text-decoration:none;
        }
    }
    img{
        width:100%;
        height:auto;
        min-height:360px;
    }
    .padding-bottom{
        margin-bottom:30px;
    }
    .content{
        border:1px solid lighten($light-grey,20);
        overflow:hidden;
        position:relative;
        display:block;

        .col-md-6{
            position:initial;
        }

        .btn-wrapper{
            position:absolute;
            right:15px;
            bottom:15px;
        }
    }
    .text{
        padding-right:15px;
        padding-top:15px;

        h3{
            margin:0;
            padding:0;
            padding-bottom:15px;

            &:after{
                margin-left:0;
            }
        }

        &.padding-left{
            padding-left:15px;
            padding-top:0;
        }
        .btn{
            font-size:0.85em;
        }
    }


    @media (max-width: 991px){
        &.portrait .content,&.landscape .content{
            height:auto;
            padding-bottom:60px;
        }

        .text{
            padding-left:15px;
        }
        .img-wrapper{
            text-align:center;
            background-size:cover;
            min-height:200px;
            height:40vh;
            background-position: center center;
            margin-bottom:20px;

            img{
                display:none;
            }

        }
    }

    @media (max-width: 767px){
        margin:7px 15px;
    }
}

.page-header{
    border-bottom:none;
}

.post-type-archive-bastiaansen_project{
    .pagination-wrapper{
        text-align:center;
    }
    .pagination{
        margin:20px auto;
        border-radius: 0px;

        &>li:first-child>a, &>li:first-child>span,
        &>li:last-child>a, &>li:last-child>span{
            border-radius:0px;
        }
    }
}
