@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans');
@import "common/variables";

@function font-url($url) {
    @return url("../../assets/fonts/" + $url);
}

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "../../bower_components/slick-carousel/slick/slick-theme.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "components/timeline";
@import "components/projects";
@import "components/spinner";
@import "components/slider";
@import "components/productsummary";
@import "components/fixedsocial";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";
